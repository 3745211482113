import { SelectionOptions, sourceForCombinedCountOptions } from "./fieldOptions"
import functionNames, {
  numberWithRequired,
  stringWithRequired
} from "./functionNames"

const functionTypes = functionNames
const SIDEBAR_ITEM = "sidebarItem"

const mathFunctions = [
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Add,
    content: "Add",
    parameters: [],
    inputType: [
      {
        type: "radio",
        name: "parameter1",
        options: SelectionOptions,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: SelectionOptions.map(i => i.value) }
        }
      },
      {
        type: "inputText",
        label: "Constant*",
        name: "parameter2",
        validation: {
          ...numberWithRequired
        },
        conditions: { name: "parameter1" }
      },
      {
        type: "autoComplete",
        name: "parameter3",
        label: "Another Source*",
        options: sourceForCombinedCountOptions,
        validation: {
          ...stringWithRequired,
          ...{
            oneOf: [...[""], ...sourceForCombinedCountOptions.map(i => i.value)]
          }
        },
        conditions: { name: "parameter1" }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Subtract,
    content: "Subtract",
    parameters: [],
    inputType: [
      {
        type: "radio",
        name: "parameter1",
        options: SelectionOptions,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: SelectionOptions.map(i => i.value) }
        }
      },
      {
        type: "inputText",
        label: "Constant*",
        name: "parameter2",
        validation: { ...numberWithRequired },
        conditions: { name: "parameter1" }
      },
      {
        type: "autoComplete",
        name: "parameter3",
        label: "Another Source*",
        options: sourceForCombinedCountOptions,
        validation: {
          ...stringWithRequired,
          ...{
            oneOf: [...[""], ...sourceForCombinedCountOptions.map(i => i.value)]
          }
        },
        conditions: { name: "parameter1" }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Multiply,
    content: "Multiply",
    parameters: [],
    inputType: [
      {
        type: "radio",
        name: "parameter1",
        options: SelectionOptions,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: SelectionOptions.map(i => i.value) }
        }
      },
      {
        type: "inputText",
        label: "Constant*",
        name: "parameter2",
        validation: { ...numberWithRequired },
        conditions: { name: "parameter1" }
      },
      {
        type: "autoComplete",
        name: "parameter3",
        label: "Another Source*",
        options: sourceForCombinedCountOptions,
        validation: {
          ...stringWithRequired,
          ...{
            oneOf: [...[""], ...sourceForCombinedCountOptions.map(i => i.value)]
          }
        },
        conditions: { name: "parameter1" }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Divide,
    content: "Divide",
    parameters: [],
    inputType: [
      {
        type: "radio",
        name: "parameter1",
        options: SelectionOptions,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: SelectionOptions.map(i => i.value) }
        }
      },
      {
        type: "inputText",
        label: "Constant*",
        name: "parameter2",
        validation: { ...numberWithRequired },
        conditions: { name: "parameter1" }
      },
      {
        type: "autoComplete",
        name: "parameter3",
        label: "Another Source*",
        options: sourceForCombinedCountOptions,
        validation: {
          ...stringWithRequired,
          ...{
            oneOf: [...[""], ...sourceForCombinedCountOptions.map(i => i.value)]
          }
        },
        conditions: { name: "parameter1" }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Round,
    content: "Round",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Decimal Places*",
        name: "parameter1",
        validation: {
          ...numberWithRequired,
          ...{
            min: {
              min: 1,
              error: "Value should be more than 1"
            }
          }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Floor,
    content: "Floor",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Decimal Places*",
        name: "parameter1",
        validation: {
          ...numberWithRequired,
          ...{
            min: {
              min: 1,
              error: "Value should be more than 1"
            }
          }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Ceil,
    content: "Ceil",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Decimal Places*",
        name: "parameter1",
        validation: {
          ...numberWithRequired,
          ...{
            min: {
              min: 1,
              error: "Value should be more than 1"
            }
          }
        }
      }
    ]
  }
].sort((a, b) => {
  if (a.function > b.function) {
    return 1
  } else if (b.function > a.function) {
    return -1
  } else {
    return 0
  }
})

export default mathFunctions
