import { CONFIG_MODULES } from "src/utils/constants"

export const LocationsList: any = {
  Location: {
    Enabled: true,
    OjKey: "Location",
    DataField: "LocationValue",
    Labels: [{ "en-us": "Tag" }],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: false,
    IsAllowEdits: true,
    ValidationInstructions: [
      {
        Function: "CheckDataType",
        Parameters: ["NUMERIC"],
        OnFail: "ERROR",
        OnFailMessage: [
          {
            "en-us": "Invalid Data Type"
          }
        ]
      },
      {
        Function: "CheckLengthRange",
        Parameters: [1, 5],
        OnFail: "ERROR",
        OnFailMessage: [
          {
            "en-us": "Lengths must be between 1 and 5"
          }
        ]
      }
    ]
  },
  SecondaryLocation1: {
    Enabled: true,
    OjKey: "SecondaryLocation1",
    DataField: "SecondaryLocation1",
    Labels: [
      {
        "en-us": "SecondaryLocation1"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: false
  },
  SecondaryLocation2: {
    Enabled: true,
    OjKey: "SecondaryLocation2",
    DataField: "SecondaryLocation2",
    Labels: [
      {
        "en-us": "SecondaryLocation2"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: false
  },
  SecondaryLocation3: {
    Enabled: true,
    OjKey: "SecondaryLocation3",
    DataField: "SecondaryLocation3",
    Labels: [
      {
        "en-us": "SecondaryLocation3"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: false
  }
}

export const ProductsList: any = {
  ProductCaptured: {
    Enabled: true,
    DataField: "CapturedProduct",
    OjKey: "Product",
    Labels: [{ "en-us": "Product" }],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  ProductBarcode: {
    Enabled: true,
    DataField: "Barcode",
    OjKey: "Barcode",
    Labels: [
      {
        "en-us": "Barcode"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  ProductSku: {
    Enabled: true,
    DataField: "Sku",
    OjKey: "SKU",
    Labels: [
      {
        "en-us": "SKU"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  ProductDescription: {
    Enabled: true,
    DataField: "Description",
    OjKey: "Description",
    Labels: [
      {
        "en-us": "Description"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  Department: {
    Enabled: true,
    DataField: "Department",
    OjKey: "Department",
    Labels: [
      {
        "en-us": "Dept Code"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  DepartmentName: {
    Enabled: true,
    DataField: "DepartmentName",
    OjKey: "DepartmentName",
    Labels: [
      {
        "en-us": "Department"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  DepartmentParent: {
    Enabled: true,
    DataField: "DepartmentParent",
    OjKey: "DepartmentParent",
    Labels: [
      {
        "en-us": "Division Code"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  DepartmentParentName: {
    Enabled: true,
    DataField: "DepartmentParentName",
    OjKey: "DepartmentParentName",
    Labels: [
      {
        "en-us": "Division"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  Price: {
    Enabled: true,
    DataField: "Price",
    OjKey: "Price",
    Labels: [
      {
        "en-us": "Price"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true,
    DecimalPlaces: 2,
    CurrencySymbol: true
  },
  ProductUOM: {
    Enabled: true,
    DataField: "Uom",
    OjKey: "ProductUOM",
    Labels: [
      {
        "en-us": "UOM"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  ProductSize: {
    Enabled: true,
    DataField: "Size",
    OjKey: "ProductSize",
    Labels: [
      {
        "en-us": "Size"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  ProductColor: {
    Enabled: true,
    DataField: "Color",
    OjKey: "ProductColor",
    Labels: [
      {
        "en-us": "Color"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  Cost: {
    Enabled: true,
    DataField: "Cost",
    OjKey: "Cost",
    Labels: [
      {
        "en-us": "Cost"
      }
    ],
    DecimalPlaces: 2,
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true,
    CurrencySymbol: true
  },
  ProductGenericT1: {
    Enabled: true,
    OjKey: "ProductGenericT1",
    Labels: [
      {
        "en-us": "ProductGenericT1"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true,
    DataField: "ProductGenericT1"
  },
  ProductGenericT2: {
    Enabled: true,
    OjKey: "ProductGenericT2",
    Labels: [
      {
        "en-us": "ProductGenericT2"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true,
    DataField: "ProductGenericT2"
  },
  ProductGenericT3: {
    Enabled: true,
    OjKey: "ProductGenericT3",
    Labels: [
      {
        "en-us": "ProductGenericT3"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true,
    DataField: "ProductGenericT3"
  },
  ProductGenericD1: {
    Enabled: true,
    OjKey: "ProductGenericD1",
    Labels: [
      {
        "en-us": "ProductGenericD1"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true,
    DataField: "ProductGenericD1"
  },
  ProductGenericD2: {
    Enabled: true,
    OjKey: "ProductGenericD2",
    Labels: [
      {
        "en-us": "ProductGenericD2"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true,
    DataField: "ProductGenericD2"
  },
  ProductGenericD3: {
    Enabled: true,
    OjKey: "ProductGenericD3",
    Labels: [
      {
        "en-us": "ProductGenericD3"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true,
    DataField: "ProductGenericD3"
  },
  DepartmentSort: {
    Enabled: true,
    OjKey: "DepartmentSort",
    Labels: [
      {
        "en-us": "DepartmentSort"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true,
    DataField: "DepartmentSort"
  },
  DepartmentParentSort: {
    Enabled: true,
    OjKey: "DepartmentParentSort",
    Labels: [
      {
        "en-us": "DepartmentParentSort"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true,
    DataField: "DepartmentParentSort"
  },
  VarianceGroup: {
    Enabled: true,
    OjKey: "VarianceGroup",
    DataField: "VarianceGroup",
    Labels: [
      {
        "en-us": "VarianceGroup"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true
  },
  Style: {
    Enabled: true,
    OjKey: "Style",
    Labels: [
      {
        "en-us": "Style"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: true,
    DataField: "Style"
  },
  Ext: {
    Enabled: true,
    OjKey: "Ext",
    Labels: [
      {
        "en-us": "Ext"
      }
    ],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: false,
    DecimalPlaces: 2,
    CurrencySymbol: true
  },
  Qty: {
    Enabled: true,
    DataField: "Quantity",
    OjKey: "Qty",
    Labels: [{ "en-us": "Qty" }],
    ShowOnTagDetails: true,
    ShowOnQtyEntry: false,
    DecimalPlaces: 0
  }
}

export const initCountFields: any = {
  Location: LocationsList.Location,
  ProductCaptured: { ...ProductsList?.ProductCaptured, IsAllowEdits: false },
  Qty: {
    ...ProductsList?.Qty,
    ...{
      IsAllowEdits: true,
      ValidationInstructions: [
        {
          Function: "CheckDataType",
          Parameters: ["DECIMAL"],
          OnFail: "ERROR",
          OnFailMessage: [
            {
              "en-us": "Invalid Data Type"
            }
          ]
        },
        {
          Function: "CheckDecimalRange",
          Parameters: [1, 999],
          OnFail: "ERROR",
          OnFailMessage: [
            {
              "en-us": "Must be between 1 and 999"
            }
          ]
        }
      ]
    }
  }
}

export const currencySymbolDisplay = [
  "Price",
  "Cost",
  "Ext",
  "ProductDecimal1",
  "ProductDecimal2",
  "ProductDecimal3",
  "ProductDecimal4",
  "ProductDecimal5"
]

export const decimalPositionDisplay = [
  "Price",
  "Cost",
  "Ext",
  "ProductDecimal1",
  "ProductDecimal2",
  "ProductDecimal3",
  "ProductDecimal4",
  "ProductDecimal5",
  "Qty",
  "ProductGenericD1",
  "ProductGenericD2",
  "ProductGenericD3"
]

export const currencies = [
  { "$ - USD - US Dollar": { original: "$", display: "$" } },
  { "$ - MXN - MEXICAN PESO": { original: "Mex$", display: "$" } },
  { "$ - CAD - Canadian Dollar": { original: "C$", display: "$" } },
  { "¥ - CNY - Chinese Yuan": { original: "¥", display: "¥" } }
]
export const columnDataDetails = [
  {
    Header: "",
    accessor: "SortOrder",
    setWidth: 2
  },
  {
    Header: "Field",
    accessor: "field",
    setWidth: 220
  },
  {
    Header: "Display Name",
    accessor: "Labels[0][en-us]",
    setWidth: 200
  },
  {
    Header: "Currency Symbol",
    accessor: "CurrencySymbol",
    setWidth: 150
  },
  {
    Header: "Decimal Places",
    accessor: "DecimalPlaces",
    setWidth: 130
  },
  {
    Header: "Show on tag details",
    accessor: "ShowOnTagDetails",
    setWidth: 100
  },
  {
    Header: "Show on Qty Entry",
    accessor: "ShowOnQtyEntry",
    setWidth: 100
  },
  {
    Header: "Actions",
    accessor: "actions",
    setWidth: 150
  }
]

export const reorderRecords = data => {
  if (data.length > 2) {
    let extItem = {}
    let pcIndex = data.findIndex(d => d.field === "ProductCaptured")
    let firstItem = data[pcIndex]
    data.splice(pcIndex, 1)
    let qtyIndex = data.findIndex(d => d.field === "Qty")
    let lastItem = data[qtyIndex]
    data.splice(qtyIndex, 1)
    let extIndex = data.findIndex(d => d.field === "Ext")
    if (extIndex !== -1) {
      extItem = data[extIndex]
      data.splice(extIndex, 1)
    }
    data = data.sort((a, b) => a.SortOrder - b.SortOrder)
    data.unshift(firstItem)
    data.push(lastItem)
    if (Object.keys(extItem).length) data.push(extItem)
  }

  return data
}

export const getCurrencySymbol = () => {
  let cMap = new Map()
  let cFullMap = new Map()
  let cDisplayMap = new Map()
  let cOriginalDisplayMap = new Map()
  let cDisplayOriginalMap = new Map()
  for (let m of Object.values(currencies)) {
    cMap.set(Object.keys(m)[0], Object.values(m)[0].original)
    cFullMap.set(Object.values(m)[0].original, Object.keys(m)[0])
    cDisplayMap.set(Object.keys(m)[0], Object.values(m)[0].display)
    cOriginalDisplayMap.set(
      Object.values(m)[0].original,
      Object.values(m)[0].display
    )
    cDisplayOriginalMap.set(
      Object.values(m)[0].display,
      Object.values(m)[0].original
    )
  }
  return {
    cMap,
    cFullMap,
    cDisplayMap,
    cOriginalDisplayMap,
    cDisplayOriginalMap
  }
}

export const instructionDetails = {
  ValidationInstructions: {
    title: "Validation_Instructions",
    required: true,
    component: CONFIG_MODULES.CountFieldInstructions.validation
  },
  LookupInstructions: {
    title: "Lookup Instructions",
    required: false,
    component: CONFIG_MODULES.CountFieldInstructions.Lookup
  }
}
