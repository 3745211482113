export const CheckDigitRadioOptions = [
  {
    value: "UPCA",
    label: "UPCA",
    default: true
  },
  {
    value: "WIS Tag",
    label: "WIS Tag"
  },
  {
    value: "CUSTOM",
    label: "CUSTOM"
  }
]

export const checkBarcodeTypePart1 = [
  //old
  {
    value: "CODE128",
    label: "CODE128"
  },
  {
    value: "QRCODE",
    label: "QRCODE"
  },
  {
    value: "CODE39",
    label: "CODE39"
  },
  {
    value: "CODE93",
    label: "CODE93"
  },
  {
    value: "UPCA",
    label: "UPCA"
  }
]

export const checkBarcodeTypePart2 = [
  //old
  {
    value: "UPCE",
    label: "UPCE"
  },
  {
    value: "UPCE0",
    label: "UPCE0"
  },
  {
    value: "UPCE1",
    label: "UPCE1"
  },
  {
    value: "EAN13",
    label: "EAN13"
  },
  {
    value: "EAN8",
    label: "EAN8"
  }
]

const checkBarcodeTypePart3 = [
  //old
  {
    value: "GS1_DATABAR",
    label: "GS1_DATABAR"
  },
  {
    value: "GS1_DATABAR_LIM",
    label: "GS1_DATABAR_LIM"
  },
  {
    value: "GS1_DATABAR_EXP",
    label: "GS1_DATABAR_EXP"
  },
  {
    value: "I2OF5",
    label: "I2OF5"
  },
  {
    value: "D2OF5",
    label: "D2OF5"
  }
]

const checkBarcodeTypePart4 = [
  //old
  {
    value: "CODE39_FULL_ASCII",
    label: "CODE39_FULL_ASCII"
  },
  {
    value: "MICROPDF",
    label: "MICROPDF"
  },
  {
    value: "MICROQR",
    label: "MICROQR"
  },
  {
    value: "MAXICODE",
    label: "MAXICODE"
  }
]

const checkBarcodeTypePart5 = [
  //new
  {
    value: "CodABar",
    label: "CodABar"
  },
  {
    value: "ISBT128",
    label: "ISBT128"
  },
  {
    value: "EAN128",
    label: "EAN128"
  },
  {
    value: "UPCEAN_SUPPLEMENTAL2",
    label: "UPCEAN_SUPPLEMENTAL2"
  },
  {
    value: "UPCEAN_SUPPLEMENTAL5",
    label: "UPCEAN_SUPPLEMENTAL5"
  }
]

const checkBarcodeTypePart6 = [
  {
    value: "MSI",
    label: "MSI"
  },
  {
    value: "Plessey Mod10",
    label: "Plessey Mod10"
  },
  {
    value: "Bookland_EAN",
    label: "Bookland_EAN"
  },
  {
    value: "Coupon_Code",
    label: "Coupon_Code"
  },
  {
    value: "Code32",
    label: "Code32"
  }
]

const checkBarcodeTypePart7 = [
  {
    value: "Aztec",
    label: "Aztec"
  },
  {
    value: "DataMatrix",
    label: "DataMatrix"
  },
  {
    value: "PDF417",
    label: "PDF417"
  }
]

export const checkBarcodeType = [
  ...checkBarcodeTypePart1,
  ...checkBarcodeTypePart2,
  ...checkBarcodeTypePart3,
  ...checkBarcodeTypePart4,
  ...checkBarcodeTypePart5,
  ...checkBarcodeTypePart6,
  ...checkBarcodeTypePart7
]

export const checkBarcodeTypeForCheckDigit = [
  ...checkBarcodeType,
  {
    value: CheckDigitRadioOptions[2].value,
    label: CheckDigitRadioOptions[2].label
  }
]

export const checkDataType = [
  { value: "NUMERIC", label: "NUMERIC" },
  { value: "DECIMAL", label: "DECIMAL" },
  { value: "ALPHANUMERIC", label: "ALPHANUMERIC" },
  { value: "TEXT", label: "TEXT" }
]
export const checkEntryMethod = [
  { value: "SCANNED", label: "SCANNED" },
  { value: "KEYED", label: "KEYED" },
  { value: "AUTO", label: "AUTO" }
]

export const checkEntryMethodPreCount = [
  { value: "PRECOUNT", label: "PRECOUNT" }
]

export const checkEntryMethodWithPrecount = [
  ...checkEntryMethod,
  ...checkEntryMethodPreCount
]

export const TrueOrFalse = [
  { value: "True", label: "True" },
  { value: "False", label: "False", default: true }
]

export const TrueOrFalseWithNoDefault = TrueOrFalse.map(i => {
  return { value: i.value, label: i.label }
})
export const LookupRoutineCopyOverRide = [
  { value: "NONE", label: "NONE", default: true },
  { value: "BARCODE", label: "BARCODE" },
  { value: "SKU", label: "SKU" },
  { value: "BOTH", label: "BOTH" }
]
export const CheckDigitAddType = [
  { value: "AddProduct", label: "AddProduct", default: true },
  { value: "AddDigit", label: "AddDigit" }
]
export const CheckDigitNextType = [
  { value: "NextHigh", label: "NextHigh", default: true },
  { value: "NextLow", label: "NextLow" }
]
export const CheckDigitModType = [
  { value: "Mod10", label: "Mod10", default: true },
  { value: "CDVType80", label: "CDVType80" },
  { value: "CDVType81", label: "CDVType81" },
  { value: "CDVType82", label: "CDVType82" },
  { value: "CDVType83", label: "CDVType83" },
  { value: "CDVType84", label: "CDVType84" },
  { value: "Mod85", label: "Mod85" }
]

export const WeightOptions = [
  {
    value: "1,3,1,3,1,3,1,3,1,3,1,3,0",
    label: "1,3,1,3,1,3,1,3,1,3,1,3,0",
    default: true
  },
  {
    value: "3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,0",
    label: "3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,3,0"
  },
  {
    value: "4,2,6,8,4,2,0",
    label: "4,2,6,8,4,2,0"
  },
  {
    value: "1,3,3,1,3,0",
    label: "1,3,3,1,3,0"
  },
  {
    value: "10,9,8,7,6,5,4,3,2,0",
    label: "10,9,8,7,6,5,4,3,2,0"
  },
  {
    value: "2,3,9,1,9,1,2,0",
    label: "2,3,9,1,9,1,2,0"
  }
]
export const SelectionOptions = [
  {
    value: "constant",
    label: "Constant"
  },
  {
    value: "Another Source",
    label: "Another Source"
  }
]
export const lookupTableOptions = [
  {
    value: "Product",
    label: "Product"
  },
  {
    value: "Department",
    label: "Department"
  },
  {
    value: "Repack",
    label: "Repack"
  }
]

const sourceRepackTableFields = [
  {
    value: "RepackBarcode",
    label: "Repack Barcode"
  }
]

const sourceProductTableFields = [
  ...[
    {
      value: "Barcode",
      label: "Barcode"
    },
    {
      value: "Sku",
      label: "Sku"
    },
    {
      value: "Description",
      label: "Description"
    },
    {
      value: "Price",
      label: "Price"
    },
    {
      value: "Cost",
      label: "Cost"
    },
    {
      value: "Color",
      label: "Color"
    },
    {
      value: "Uom",
      label: "Uom"
    },
    {
      value: "Size",
      label: "Size"
    },
    {
      value: "Style",
      label: "Style"
    }
  ],
  ...[1, 2, 3].map(i => {
    return { value: `ProductGenericT${i}`, label: `ProductGenericT${i}` }
  }),

  ...[1, 2, 3].map(i => {
    return { value: `ProductGenericD${i}`, label: `ProductGenericD${i}` }
  })
]
const sourceDepartmentTableFields = [
  ...[
    {
      value: "Department",
      label: "Department"
    },
    {
      value: "DepartmentName",
      label: "DepartmentName"
    },
    {
      value: "DepartmentSort",
      label: "DepartmentSort"
    },
    {
      value: "DepartmentParent",
      label: "DepartmentParent"
    },
    {
      value: "DepartmentParentName",
      label: "DepartmentParentName"
    },
    {
      value: "DepartmentParentSort",
      label: "DepartmentParentSort"
    }
  ]
]
const sourceOtherTableFields = [
  {
    value: "ProductCaptured",
    label: "ProductCaptured"
  },
  {
    value: "Quantity",
    label: "Quantity"
  },
  {
    value: "Ext",
    label: "Ext"
  }
]
const repackOtherSourceFields = [
  {
    value: "{RepackLookup}",
    label: "{RepackLookup}"
  },
  {
    value: "{RepackLookup.RepackBarcode}",
    label: "{RepackLookup.RepackBarcode}"
  }
]
export const currentFieldOptions = [
  { value: "Current Field", label: "Current Field", default: true }
]

export const lookupProductOptions = [
  ...sourceProductTableFields,
  ...[sourceDepartmentTableFields[0]]
]

export const lookupDepartmentOptions = [...sourceDepartmentTableFields]
export const lookupRepackOptions = [...sourceRepackTableFields]

export const sourceForCountLocationOptions = [
  ...[
    {
      value: "{Location}",
      label: "{Location}"
    }
  ],
  ...[...sourceProductTableFields, ...sourceDepartmentTableFields].map(i => {
    return {
      value: `{${i.value}}`,
      label: `{${i.label}}`
    }
  }),
  ...[
    {
      value: "{LocationRange}",
      label: "{LocationRange}"
    },
    {
      value: "{LocationCategory}",
      label: "{LocationCategory}"
    }
  ],
  ...sourceOtherTableFields.map(i => {
    return {
      value: `{${i.value}}`,
      label: `{${i.value}}`
    }
  }),
  ...repackOtherSourceFields
]

export const sourceForCountProductOptions = [
  ...[
    {
      value: "{ProductLookup}",
      label: "{ProductLookup}"
    }
  ],
  ...sourceProductTableFields.map(i => {
    return {
      value: `{ProductLookup.${i.value}}`,
      label: `{ProductLookup.${i.label}}`
    }
  }),
  ...[
    {
      value: `{ProductLookup.${sourceDepartmentTableFields[0].value}}`,
      label: `{ProductLookup.${sourceDepartmentTableFields[0].value}}`
    }
  ]
]

export const sourceForCountDepartmentOptions = [
  ...[
    {
      value: "{DepartmentLookup}",
      label: "{DepartmentLookup}"
    }
  ],
  ...sourceDepartmentTableFields.map(i => {
    return {
      value: `{DepartmentLookup.${i.value}}`,
      label: `{DepartmentLookup.${i.label}}`
    }
  })
]

export const sourceForCombinedCountOptions = [
  ...sourceForCountLocationOptions,
  ...sourceForCountProductOptions,
  ...sourceForCountDepartmentOptions
]

const sourceForAuditTagLevelOptionsPart1 = [
  {
    value: "{Location.Value}",
    label: "{Location.Value}"
  },
  {
    value: "{Location.AuditReason}",
    label: "{Location.AuditReason}"
  },
  {
    value: "{Location.AuditRequired}",
    label: "{Location.AuditRequired}"
  },
  {
    value: "{Location.TotalQty}",
    label: "{Location.TotalQty}"
  },
  {
    value: "{Location.TotalExt}",
    label: "{Location.TotalExt}"
  }
]

const sourceForAuditTagLevelOptionsPart2 = [
  {
    value: "{Location.MajorityDepartment}",
    label: "{Location.MajorityDepartment}"
  },
  {
    value: "{Location.MajorityDepartmentPercent}",
    label: "{Location.MajorityDepartmentPercent}"
  },
  {
    value: "{Range.Name}",
    label: "{Range.Name}"
  },
  {
    value: "{Category.Field}",
    label: "{Category.Field}"
  },
  {
    value: "{Category.Name}",
    label: "{Category.Name}"
  }
]

export const sourceForAuditTagLevelOptions = [
  ...sourceForAuditTagLevelOptionsPart1,
  ...sourceForAuditTagLevelOptionsPart2,
  ...[
    {
      value: "{CountRole}",
      label: "{CountRole}"
    },
    {
      value: "{CountedAQ}",
      label: "{CountedAQ}"
    }
  ]
]

export const sourceForAuditRecordLevelOptions = [
  ...sourceForAuditTagLevelOptions,
  ...[
    ...sourceProductTableFields,
    ...sourceDepartmentTableFields,
    ...sourceOtherTableFields
  ].map(i => {
    return {
      value: `{Item.${i.value}}`,
      label: `{Item.${i.label}}`
    }
  })
]

export const sourceForCombinedAuditOptions = [
  ...sourceForAuditTagLevelOptions,
  ...sourceForAuditRecordLevelOptions
]

export const errorOptions = [
  {
    value: "ERROR",
    label: "ERROR"
  },
  {
    value: "WARN",
    label: "WARN"
  }
]

export const preCountDataOptions = [
  {
    value: "{product_captured_input_method}",
    label: "{product_captured_input_method}"
  },
  {
    value: "{Location.IsPreCount}",
    label: "{Location.IsPreCount}"
  }
]
